<template>
  <div class="empty-address-book" :class="type" data-t="empty-address-book">
    <div>
      <img src="./assets/no-items.svg" class="image" />
    </div>
    <div class="content">
      <div class="title">
        {{ t('payments.withdrawal.emptyAddressBook') }}
      </div>
      <div class="text">
        {{ t('payments.withdrawal.emptyAddressBookHint') }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
withDefaults(defineProps<{ type: 'desktop' | 'mobile' }>(), {
  type: 'desktop',
})

const { t } = useI18n()
</script>

<style scoped>
.empty-address-book {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-height: 236px;
  padding: var(--spacing-150);

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: var(--spacing-250);

    text-align: center;
  }

  .title {
    font: var(--desktop-text-md-semibold);
  }

  .text {
    font: var(--desktop-text-sm-medium);
    color: var(--palette-light-600);
  }
}

.empty-address-book.mobile {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;

  .image {
    width: 52px;
    height: 52px;
  }

  .content {
    margin-top: var(--spacing-200);
  }

  .text {
    margin-top: var(--spacing-050);
  }
}
</style>
